import { call, put } from 'redux-saga/effects';

import api from 'api';

import { getUserFeatures } from 'store/user/actions';
import { restoreSubscription } from 'store/subscriptions/actions';
import { openModal } from 'store/modals/actions';

import { trackScreenLoad } from 'services/analytics/trackers/mainTrackers';

import { getRestoreSuccessModalData } from 'helpers/subscriptions';
import { checkSubscriptionRestored } from '../helpers';

import { ModalName } from 'components/Modals/types';

import { ISubscription } from 'types/subscription';

import { pollGetSubscriptions } from './pollGetSubscriptions';

export function* requestRestoreSubscription({ payload }: ReturnType<typeof restoreSubscription>) {
    try {
        yield call(api.subscriptions.restoreSubscription, { external_id: payload.external_id });

        const [subscription]: ISubscription[] = yield call(
            pollGetSubscriptions,
            checkSubscriptionRestored(payload.external_id)
        );

        const { external_id, product } = subscription;

        yield put(openModal(ModalName.SuccessModal, getRestoreSuccessModalData(product)));

        yield call(trackScreenLoad, 'restore_success', {
            subscription_id: external_id,
            period: product.subscription_period,
            tariff: product.name,
            content_id: product.id,
        });

        yield put(getUserFeatures());
    } catch (error) {
        yield put(openModal(ModalName.RestoreSubscriptionErrorModal, payload));

        console.error(error);
    }
}

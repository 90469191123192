import { put, call, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { IResultResponse } from 'api/types/response';
import api from 'api';

import { checkSubscriptionRestored } from 'store/subscriptions/helpers';
import { requestResetSubscription } from 'store/subscriptions/actions';
import { openModal } from 'store/modals/actions';

import { DISCOUNT_TYPES } from 'constants/subscriptions';
import { CANCEL_OFFER_SUCCESS_SCREEN_ID, DISCOUNT_APPLIED_ERROR, DISCOUNT_APPLIED_SUCCESS } from 'constants/analytics';

import { sendAnalyticDiscountAppliedResult, trackScreenLoadCancelOffer } from 'services/analytics';

import { changeSubscriptionErrorModalData, getChangeSubscriptionSuccessModalData } from 'helpers/subscriptions';

import { ModalName } from 'components/Modals/types';

import { CancelFlowOffers, IDiscountSubscription, ISubscription } from 'types/subscription';

import { pollGetSubscriptions } from './pollGetSubscriptions';
import { selectAllSubscriptions, selectDiscountSubscription } from '../selectors';

/**
 *
 * @param isExtraOffer boolean
 *
 * There is no way to apply a discount to a canceled subscription.
 * The resetSubscription method calls the reset subscription endpoint,
 * which restores the subscription and applies the discount under the hood.
 */
export function* resetSubscription({
    payload: isExtraOffer,
}: ReturnType<typeof requestResetSubscription>): SagaIterator {
    try {
        const [subscription]: ISubscription[] = yield select(selectAllSubscriptions);
        const discountProduct: IDiscountSubscription = yield select(selectDiscountSubscription);

        yield put(openModal(ModalName.WithLoaderModal, { title: 'subscription.waitingModal.updatingPlan.title' }));

        const { result }: IResultResponse = yield call(api.subscriptions.resetSubscription, {
            product: discountProduct,
            discount_type: isExtraOffer ? DISCOUNT_TYPES.extra : DISCOUNT_TYPES.base,
            external_id: subscription.external_id,
        });

        if (!result) {
            throw Error('reset subscription error');
        }

        yield call(pollGetSubscriptions, checkSubscriptionRestored(subscription.external_id));

        yield call(trackScreenLoadCancelOffer, {
            screenId: CANCEL_OFFER_SUCCESS_SCREEN_ID,
            eventLabel: { subscription_id: subscription.external_id, offer: CancelFlowOffers.Discount },
        });

        yield put(openModal(ModalName.SuccessModal, getChangeSubscriptionSuccessModalData(discountProduct)));

        yield call(sendAnalyticDiscountAppliedResult, DISCOUNT_APPLIED_SUCCESS, subscription.product.name);
    } catch (error) {
        yield put(
            openModal(ModalName.RetryErrorModal, {
                ...changeSubscriptionErrorModalData,
                retryAction: () => requestResetSubscription(isExtraOffer),
            })
        );

        yield call(
            sendAnalyticDiscountAppliedResult,
            DISCOUNT_APPLIED_ERROR,
            'Subscription plan not updated due to a technical issue'
        );
    }
}

/* eslint-disable max-lines */
import i18next from 'i18next';

import { SUBSCRIPTION_STATES } from 'constants/subscriptions';
import {
    DAYS_PER_MONTH,
    DAYS_PER_SIX_MONTH,
    DAYS_PER_SIX_MONTH_INCORRECT,
    DAYS_PER_THREE_MONTH,
    DAYS_PER_YEAR,
} from 'constants/periods';
import { CURRENCY_SIGN_MAP } from 'constants/currency';

import { getPriceWithDecimals } from 'helpers/prices';
import { getFormatedDate } from 'helpers/getFormatedDate';

import { SuccessModalPropsType } from 'components/Modals/Subscriptions/SuccessModal/types';

import raised_hands2 from 'assets/emoji/raised_hands2.png';
import error from 'assets/emoji/exclamation2.svg';
import check_mark from 'assets/emoji/check_mark.webp';
import check_in_circle from 'assets/emoji/check_in_circle.svg';

import { CancelFlowOffers, IDiscountSubscription, ISubscription, SubscriptionPauseType } from 'types/subscription';
import { Product, SubscriptionProductCodes } from 'types/product';

export const isMainSubscription = (productCode: ISubscription['product_code']): boolean =>
    productCode === SubscriptionProductCodes.AppFullAccess || productCode === SubscriptionProductCodes.Main;

export const sortMainSubscriptionFirst = (subscriptions: Array<ISubscription>): Array<ISubscription> =>
    subscriptions.sort((a) => (isMainSubscription(a.product_code) ? -1 : 0));

export const getSubscriptionsByStatus = (subscriptions: Array<ISubscription>) => {
    const activeSubscriptions: ISubscription[] = [];
    const canceledSubscriptions: ISubscription[] = [];
    const expiredSubscriptions: ISubscription[] = [];

    const sortedSubscriptions = sortMainSubscriptionFirst(subscriptions);

    sortedSubscriptions.forEach((e) => {
        const isActive = e.state === SUBSCRIPTION_STATES.ACTIVE;

        if (isActive && (Boolean(e.cancelled_at) || Boolean(e.internal_cancel_at))) {
            canceledSubscriptions.push(e);
        }
        if (
            (!isActive && (Boolean(e.cancelled_at) || Boolean(e.internal_cancel_at))) ||
            e.state === SUBSCRIPTION_STATES.CHARGEBACK
        )
            expiredSubscriptions.push(e);
        if (
            (isActive || e.state === SUBSCRIPTION_STATES.REDEMPTION || e.state === SUBSCRIPTION_STATES.Paused) &&
            !Boolean(e.cancelled_at) &&
            !Boolean(e.internal_cancel_at)
        )
            activeSubscriptions.push(e);
    });

    return { sortedSubscriptions, activeSubscriptions, canceledSubscriptions, expiredSubscriptions };
};

export const getSubscriptionData = ({ subscription_period, amount, currency }: IDiscountSubscription | Product) => ({
    period: subscription_period === DAYS_PER_SIX_MONTH_INCORRECT ? DAYS_PER_SIX_MONTH : subscription_period,
    type: i18next.t('subscription.type.subscription'),
    price: getPriceWithDecimals({ price: amount, currency }),
});

export const getSubscriptionSubtitle = (subscription_period: number) => {
    switch (subscription_period) {
        case DAYS_PER_MONTH:
            return 'subscription.successModal.newSubscription.subtitle.monthly';

        case DAYS_PER_THREE_MONTH:

        case DAYS_PER_SIX_MONTH:

        case DAYS_PER_SIX_MONTH_INCORRECT:
            return 'subscription.successModal.newSubscription.subtitle.moreThanOneMonth';

        case DAYS_PER_YEAR:
            return 'subscription.successModal.newSubscription.subtitle.annual';

        default:
            return 'subscription.successModal.newSubscription.subtitle.monthly';
    }
};

export const getChangeSubscriptionSuccessModalData = ({
    amount,
    currency,
    subscription_period,
}: IDiscountSubscription): SuccessModalPropsType => ({
    title: i18next.t(
        subscription_period === DAYS_PER_YEAR
            ? 'subscription.successModal.canceled.title.annual'
            : 'subscription.successModal.canceled.title',
        {
            period: subscription_period,
        }
    ),
    subtitle: i18next.t(getSubscriptionSubtitle(subscription_period), {
        currencySign: CURRENCY_SIGN_MAP[currency],
        price: getPriceWithDecimals({ price: amount, currency }),
        period: subscription_period,
    }),
    buttonText: 'basics.done',
    image: {
        src: check_in_circle,
    },
});

export const getCancelSubscriptionSuccessModalData = (subscriptions: ISubscription[]): SuccessModalPropsType => {
    const [firstSub, secondSub] = subscriptions;

    const {
        targetMonth: targetMonth1,
        targetDay: targetDay1,
        targetYear: targetYear1,
    } = getFormatedDate(firstSub.expired_date);

    const getTitleAndSubtitle = () => {
        if (secondSub) {
            const {
                targetMonth: targetMonth2,
                targetDay: targetDay2,
                targetYear: targetYear2,
            } = getFormatedDate(secondSub.expired_date);

            return {
                title: i18next.t('subscription.successModal.cancelSubscription.multiple.title', {
                    firstCode: firstSub.product_code,
                    secondCode: secondSub.product_code,
                }),
                subtitle: i18next.t('subscription.successModal.cancelSubscription.multiple.subtitle', {
                    firstCode: firstSub.product_code,
                    secondCode: secondSub.product_code,
                    targetMonth1,
                    targetDay1,
                    targetYear1,
                    targetMonth2,
                    targetDay2,
                    targetYear2,
                }),
            };
        } else {
            return {
                title: i18next.t('subscription.successModal.cancelSubscription.title', {
                    product_code: firstSub.product_code,
                }),
                subtitle: i18next.t('subscription.successModal.cancelSubscription.subtitle', {
                    targetMonth: targetMonth1,
                    targetDay: targetDay1,
                    targetYear: targetYear1,
                }),
            };
        }
    };

    const { title, subtitle } = getTitleAndSubtitle();

    return {
        title,
        subtitle,
        buttonText: 'basics.continue',
        image: {
            src: raised_hands2,
        },
    };
};

export const changeSubscriptionErrorModalData = {
    img: { src: error },
    title: 'subscription.discount.error.title',
    subtitle: 'subscription.discount.error.subtitle',
    subject: 'subscription.changeSubscription.error.title',
};

export const getCancelSubscriptionErrorModalData = ({
    isSingleError,
    subscriptionsCancellationFailedPartially,
}: {
    isSingleError: boolean;
    subscriptionsCancellationFailedPartially: boolean;
}) => ({
    img: { src: error },
    title: isSingleError ? 'subscription.cancel.error.title.single' : 'subscription.cancel.error.title.multi',
    subject: 'subscription.cancel.error.title',
    subtitle: isSingleError ? 'subscription.cancel.error.subtitle.single' : 'subscription.cancel.error.subtitle.multi',
    wasSecondCanceled: subscriptionsCancellationFailedPartially,
});

export const getRequestResumeSubscriptionErrorModalData = (externalIds: string[]) => ({
    img: { src: error },
    title: 'subscription.errorModal.wasntUpdated.title',
    subtitle: 'subscription.errorModal.wasntUpdated.subtitle',
    subscriptionId: externalIds,
    offer: CancelFlowOffers.Pause,
});

export const getRestoreSuccessModalData = ({
    amount,
    currency,
    subscription_period,
}: Product): SuccessModalPropsType => ({
    title: 'subscription.successRestoreModal.title',
    image: {
        src: check_mark,
    },
    subtitle: i18next.t('subscription.restoreModal.subscriptionPeriod', {
        period: subscription_period,
        currencySign: CURRENCY_SIGN_MAP[currency],
        price: getPriceWithDecimals({ price: amount, currency }),
    }),
    buttonText: 'basics.continue',
});

export const getFreeMonthSuccessModalData = (expiredDate: string) => {
    const nextMonthDate = new Date(expiredDate);

    const formattedDate = nextMonthDate
        .toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',
        })
        .replaceAll('/', '-');

    return {
        title: 'subscription.successModal.freeMonth.title',
        image: {
            src: check_in_circle,
        },
        subtitle: i18next.t('subscription.successModal.freeMonth.subttile', {
            date: formattedDate,
        }),
        buttonText: 'basics.continue',
        offer: CancelFlowOffers.FreeMonth,
    };
};

export const pauseSuccessModalData = {
    image: {
        src: check_in_circle,
    },
    title: 'subscription.successModal.pause.title',
    buttonText: 'basics.gotIt',
    offer: CancelFlowOffers.Pause,
};

export const getResumeSuccessModalData = (date: string) => {
    const formattedDate = new Date(date)
        .toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',
        })
        .replaceAll('/', '-');

    return {
        image: {
            src: check_in_circle,
        },
        title: 'subscription.successModal.resume.title',
        subtitle: i18next.t('subscription.successModal.freeMonth.subttile', {
            date: formattedDate,
        }),
        buttonText: 'basics.gotIt',
    };
};

export const reminderSuccessModalData = {
    image: {
        src: check_in_circle,
    },
    title: 'subscription.successModal.reminder.title',
    subtitle: 'subscription.successModal.reminder.subtitle',
    buttonText: 'basics.gotIt',
};

export const setReminderErrorModalData = {
    img: { src: error },
    title: 'basics.appError',
};

export const getRequestPauseSubscriptionWaitingModalData = (pauseType: SubscriptionPauseType) => ({
    title:
        pauseType === SubscriptionPauseType.Pause
            ? 'subscription.waitingModal.pause.title'
            : 'subscription.waitingModal.updatingPlan.title',
    img: null,
});

export const getRequestPauseSubscriptionErrorModalData = (externalIds: string[], pauseType: SubscriptionPauseType) => ({
    img: { src: error },
    title: 'subscription.errorModal.wasntUpdated.title',
    subtitle: 'subscription.errorModal.wasntUpdated.subtitle',
    subscriptionId: externalIds,
    offer: pauseType === SubscriptionPauseType.Pause ? CancelFlowOffers.Pause : CancelFlowOffers.FreeMonth,
});
